/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Palanquin+Dark:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import './assets/flag-icons-master/css/flag-icon.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
// variables --------
:root {
  --mainBg: #0c003e;
  --containerBg: #1a084b;
  --containerBgLight: '';
  --mainText: #fff;
  --yellowBgMain: #ffb400;
  --yellowBgLight: '';
  --linkTextLight: '#0EA5E9';
  --secondaryTextMain: #fff;
  --mainTextLight: '#022D46';
  --tertiaryTextMain: #a093ff;
  --tertiaryTextHoverMain: #c2b9ff;
  --selectedTextMain: #40167e;
  --selectedTextLight: '';
  --lightBg: #f1e7dd;
  --mainTextLight: #000;
  --secondaryTextLight: #02040c;
  --tertiaryTextLight: #615866;
  --tertiaryTextHoverLight: #444047;
  --mainBtn: #6d1e9e;
  --lightBtn: #b5a9bb;
  --secondaryMainBtn: #47198a;
  --secondaryLightBtn: '';
  --line: #341c64;
  --lineLight: '';
  --minorText: #8675ff;
  --minorTextLight: #654576;
  --tertiaryBgMain: #241257;
  --searchInputMainBg: #2e1561;
  --searchInputLightnBg: '';
  --tertiaryBgLight: '';
  --mainHover: #38006d;
  --mainHoverLight: '';
}
// variables --------

* {
  transition: all 0.3s ease;
  touch-action: pan-y;
}

input:focus {
  outline: none;
}

body,
html {
  width: 100%;
  // @media (min-width: 768px) and (max-width: 1920px) {
  //   font-size: 0.875rem; // 14px
  // }
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
  background-color: #eff6ff;
}
a {
  color: #0ea5e9;
}

::-webkit-scrollbar {
  background: #eff6ff;
  position: absolute;
  width: 0.4rem;
  direction: rtl;
  margin-left: auto;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(31, 31, 31, 0.3);
  opacity: 0.2;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: #359dd2;
  border-radius: 1rem;
  min-height: 100px;
}
/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #359dd2;
  display: none;
}
/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent var(--secondaryTextMain) transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: var(--secondaryTextMain) transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.dark ::-webkit-scrollbar {
  background: var(--tertiaryBgMain);
  position: absolute;
  width: 0.4rem;
  direction: rtl;
  margin-left: auto;
}
.dark ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 0.2;
  border-radius: 1rem;
}

.dark ::-webkit-scrollbar-thumb {
  background: var(--secondaryMainBtn);
  border-radius: 1rem;
  min-height: 100px;
}
/* Buttons */
.dark ::-webkit-scrollbar-button:single-button {
  background-color: var(--tertiaryBgMain);
  display: none;
}
/* Up */
.dark ::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent var(--secondaryTextMain) transparent;
}
.dark ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.dark ::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: var(--secondaryTextMain) transparent transparent transparent;
}
.dark ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

input {
  font-size: 1rem !important;
}
input:disabled {
  opacity: 1;
  -webkit-text-fill-color: var(--mainTextLight);
}
@layer utilities {
  ol {
    color: var(--mainTextLight);
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mainInput:-webkit-autofill,
  .mainInput:-webkit-autofill:hover,
  .mainInput:-webkit-autofill:focus,
  .mainInput:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f0f9ff inset !important;
  }
  .mainInput:-webkit-autofill {
    -webkit-text-fill-color: #64748b !important;
  }
  .lightInput:-webkit-autofill,
  .lightInput:-webkit-autofill:hover,
  .lightInput:-webkit-autofill:focus,
  .lightInput:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f1e7dd inset !important;
  }
  .lightInput:-webkit-autofill {
    -webkit-text-fill-color: #000 !important;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .stop-scroll {
    overflow-y: hidden !important;
  }
  .stop-click {
    pointer-events: none !important;
    touch-action: none !important;
  }
  .stop-scroll-safari {
    height: 100vh !important;
    overflow: hidden !important;
    width: 100% !important;
    overscroll-behavior: none !important;
    touch-action: none !important;
    -webkit-overflow-scrolling: none !important;
    position: fixed !important;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .custom-scrollbar::-webkit-scrollbar {
    background: var(--tertiaryBgMain);
    position: absolute;
    width: 0.4rem;
    direction: rtl;
    margin-left: auto;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    opacity: 0.2;
    border-radius: 1rem;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: var(--secondaryMainBtn);
    border-radius: 1rem;
    min-height: 100px;
  }
  /* Buttons */
  .custom-scrollbar::-webkit-scrollbar-button:single-button {
    display: none;
  }
  /* Up */
  .custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent var(--secondaryTextMain) transparent;
  }
  .custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
  }
  /* Down */
  .custom-scrollbar::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 8px 8px 0 8px;
    border-color: var(--secondaryTextMain) transparent transparent transparent;
  }
  .custom-scrollbar::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #777777 transparent transparent transparent;
  }
  .form-custom-check-input {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    background: none;
    background-image: url(https://cdn.chipychapa.com//welle/General_site/Categories/providers/checkbox_unselected.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .form-custom-check:hover .form-custom-check-input:not(:checked) {
    background-image: url(https://cdn.chipychapa.com//welle/General_site/Categories/providers/checkbox_hover_for_selectition.svg);
  }
  .form-custom-check-input:checked {
    background-image: url(https://cdn.chipychapa.com//welle/General_site/Categories/providers/checkbox_selected.svg);
  }
  .form-custom-check:hover .form-custom-check-input:checked {
    background-image: url(https://cdn.chipychapa.com//welle/General_site/Categories/providers/checkbox_hover_for_unselect.svg);
  }
}

.gradient-animation-bg {
  background-size: 200% 200% !important;
  animation: gradientAnimation 8s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.pause {
  .pause-bg {
    background-image: linear-gradient(263.3deg, #65808c 0%, #acb6c4 100%) !important;
  }
}
